body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: black;
  position: absolute;
  top:0;bottom:0;right:0;left:0;
  background:black;
  font-family: 'Open Sans', sans-serif;
  display: flex;
}


#root {
  flex:1;
}

input {
  -webkit-appearance: none;
  -webkit-border-radius:0; 
  border-radius:0;
}